/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
import { logError } from 'services/Logger';
import { LanguageTags } from 'i18n';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitHandler } from 'react-hook-form';
import AuthContext from '../../../../contexts/auth/context';
import { IFormPreferences } from './types';
import { saveUserPreferences } from '../../../../services/saveUserPreferences';
import { saveAgentInfo } from '../../../../services/saveAgentInfo';
import { getDepartmentsOfAgent } from '../../../../services/getDepartmentsOfAgent';
import PreferencesContext from '../../../../contexts/preferences/context';

const usePreferences = (handleClose?: () => void) => {
  const { i18n } = useTranslation();
  const { currentUserInfo, getUserInfo, getLivechatInfo, updateUserflow } = useContext(AuthContext);
  const { selectedLanguage, maxNumberSimultaneousChat, globalFontSize, updateGlobalFontSize, setLoading } =
    useContext(PreferencesContext);

  const onSubmit: SubmitHandler<IFormPreferences> = async (form) => {
    setLoading(true);
    try {
      const enableNotifications = form.desktopNotifications;
      const enableSound = form.soundNotifications;
      const maxNumberSimultaneousChats = Number.parseInt(form.maxNumberSimultaneousChat ?? '0', 10);
      const maxChats = maxNumberSimultaneousChats > 0 ? maxNumberSimultaneousChats : 0;

      const pSavePreferences = saveUserPreferences(
        selectedLanguage || LanguageTags.DEFAULT,
        enableNotifications ? 'all' : 'nothing',
        enableSound ? 100 : 0,
        globalFontSize
      );

      const userId = currentUserInfo._id;
      const {
        data: { departments = [] },
      } = await getDepartmentsOfAgent(userId);

      const pSaveAgentInfo = saveAgentInfo(
        userId,
        departments.map((d) => d.departmentId),
        maxChats === 0 ? '' : maxChats.toString()
      );

      await Promise.all([pSavePreferences, pSaveAgentInfo]);

      setLoading(false);
      getUserInfo();
      getLivechatInfo();
      i18n.changeLanguage(selectedLanguage || LanguageTags.DEFAULT);
      updateUserflow(selectedLanguage);
      updateGlobalFontSize(globalFontSize);
      handleClose?.();
    } catch (error) {
      logError('Error while setting user preferences.', error);
      setLoading(false);
    }
  };

  return {
    onSubmit,
    maxNumberSimultaneousChat,
  };
};

export default usePreferences;

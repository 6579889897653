import React, { useContext, useState, useEffect } from 'react';
import { Grid, Avatar, Box, Stack, Typography } from '@mui/material';
import { Button, IconButton, Tooltip } from '@engyalo/design-system';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Contact, ContactBody, Phone, TextItem, Tags, ContactInfoView, TableContainer } from './style';
import { stringAvatar } from '../../../helpers/stringAvatar';
import { IContactInfoProps, tag } from './types';
import Divider from '../../Divider';
import { CustomChip } from '../../Tags/style';
import DrawerContext from '../../../contexts/drawer/context';
import { tagsColor } from '../../../helpers/tagsColors';
import { ReactComponent as Loading } from '../../../assets/icons/loadingYalo.svg';
import MenuChatsContext from '../../../contexts/menuChats/context';
import ConversationContext from '../../../contexts/conversation/context';
import ChatsContext from '../../../contexts/chats/context';
import GenericModal from '../../Modal';
import CustomTable from '../../Table';
import { CustomFieldsType } from '../../../types/customFields';

const ContactInformation: React.FC<IContactInfoProps> = (props) => {
  const navigate = useNavigate();
  const { maskPhoneNumber } = props;

  const { handleServiceSelected } = useContext(ChatsContext);
  const { contactInfo, loading, customFields, setEditing, getAllNotificationsContact, headerColumns, tableRows } =
    useContext(DrawerContext);
  const { setOpenContactManager } = useContext(MenuChatsContext);
  const { handleStartNewConversation, handleSelectContact } = useContext(ConversationContext);
  const { t } = useTranslation();

  const [tags, setTags] = useState<Array<tag>>();
  const [loadingTags, setLoadingTags] = useState(true);
  const [tooltipTitle, setTooltipTitle] = useState(t('copy'));
  const [showModaAllContactNotifications, setShowModaAllContactNotifications] = useState(false);

  const handleTooltipTitle = () => {
    setTooltipTitle(t('copied'));
  };

  const handleShowAllContactNotifications = (contactId: string) => {
    getAllNotificationsContact(contactId);
    setShowModaAllContactNotifications(true);
  };

  useEffect(() => {
    if (contactInfo && contactInfo.livechatData?.tags && loading === false) {
      const values = contactInfo?.livechatData?.tags.split(';');

      setTags(
        values?.map((tagName: string) => {
          const color = tagsColor(tagName);
          return {
            tag: tagName,
            customColor: color,
          };
        })
      );
      setLoadingTags(false);
    }

    if (contactInfo && !contactInfo.livechatData?.tags) {
      setLoadingTags(false);
    }
  }, [contactInfo, contactInfo?.livechatData?.tags, loading]);

  return (
    <>
      {contactInfo ? (
        <div
          data-testid="contactInfo"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            overflowY: 'hidden',
          }}
        >
          <Grid container direction="column" sx={{ maxHeight: 'calc(100% - 52px)' }}>
            {loading ? (
              <Box data-testid="loading" alignSelf="center">
                <Loading />
              </Box>
            ) : (
              <div style={{ overflowY: 'scroll' }}>
                <Contact>
                  <ContactBody>
                    <Stack direction="row" alignItems="center" gap="20px">
                      <Avatar {...stringAvatar(contactInfo.name)} aria-label={t('contactAvatar')} src="" />
                      <Typography variant="body1">{contactInfo.name}</Typography>
                    </Stack>
                    <IconButton
                      data-testid="sendActiveMessage"
                      color="primary"
                      size="medium"
                      onClick={() => {
                        handleSelectContact({
                          _id: contactInfo._id,
                          name: contactInfo.name,
                          phone: contactInfo.phone[0].phoneNumber,
                          lastChat: contactInfo.lastChat._id,
                          tagsList: contactInfo.livechatData.tags,
                        });
                        handleServiceSelected({});
                        setOpenContactManager(false);
                        handleStartNewConversation(true);
                        navigate('/', { replace: true });
                      }}
                    >
                      <Tooltip title={`${t('startNewConversation')}`} placement="top">
                        <i className="fa-regular fa-comment-plus fa-sm" />
                      </Tooltip>
                    </IconButton>
                  </ContactBody>
                </Contact>
                <Contact>
                  <Typography variant="h4">{t('contactData')}</Typography>
                  <ContactInfoView>
                    <Typography variant="body2">{t('number')}: </Typography>
                    <Tooltip
                      title={tooltipTitle}
                      placement="right"
                      onClose={() => {
                        setTooltipTitle(t('copied'));
                      }}
                      leaveDelay={500}
                    >
                      <Phone
                        variant="body1"
                        color="neutral.var90"
                        data-testid="handleTooltipButtonPhone"
                        onClick={() => {
                          navigator.clipboard.writeText(
                            maskPhoneNumber(contactInfo.phone ? contactInfo.phone[0].phoneNumber : '')
                          );
                          handleTooltipTitle();
                        }}
                      >
                        {maskPhoneNumber(contactInfo.phone ? contactInfo.phone[0].phoneNumber : '')}
                      </Phone>
                    </Tooltip>
                  </ContactInfoView>

                  <ContactInfoView>
                    <Typography variant="body2">{t('email')}: </Typography>
                    {contactInfo.visitorEmails.length ? (
                      <Typography data-testid="contactEmail" variant="body1">
                        {contactInfo.visitorEmails[0]?.address}
                      </Typography>
                    ) : (
                      <Typography data-testid="contactEmailEmpty" variant="body1">
                        {t('withoutContactDocument')}
                      </Typography>
                    )}
                  </ContactInfoView>

                  {customFields &&
                    contactInfo.livechatData &&
                    Object.entries(contactInfo.livechatData).map(([key, value]: [string, string]) => {
                      const item = customFields.find(
                        (ctField: CustomFieldsType) => ctField._id === key && ctField._id !== 'tags'
                      );

                      if (item) {
                        if (item.label) {
                          return (
                            <ContactInfoView key={key}>
                              <Typography
                                variant="body2"
                                sx={{
                                  ':first-letter': {
                                    textTransform: 'capitalize',
                                  },
                                }}
                              >
                                {item.label}:{' '}
                              </Typography>
                              <Tooltip
                                onClose={() => {
                                  setTooltipTitle(t('copied'));
                                }}
                                title={tooltipTitle}
                                leaveDelay={500}
                                placement="right"
                              >
                                <TextItem
                                  data-testid="contactDocument"
                                  onClick={() => {
                                    navigator.clipboard.writeText(value);
                                    handleTooltipTitle();
                                  }}
                                >
                                  {value || t('withoutContactDocument')}
                                </TextItem>
                              </Tooltip>
                            </ContactInfoView>
                          );
                        }
                      }
                    })}

                  <Typography variant="h4" style={{ marginTop: '34px' }}>
                    {t('contactTags')}
                  </Typography>

                  {(!tags || !tags.length) && !loadingTags && <TextItem isEmpty>{t('withoutContactTags')}</TextItem>}

                  {tags && tags.length && (
                    <Tags>
                      {tags?.map((tag, idx) => (
                        <CustomChip key={idx} label={tag.tag} customcolor={tag.customColor} />
                      ))}
                    </Tags>
                  )}

                  {contactInfo.lastNotificationInfo ? (
                    <Stack
                      data-testid="lastNotification"
                      direction="row"
                      justifyContent="space-between"
                      paddingTop="16px"
                    >
                      <ContactInfoView>
                        <Typography variant="body2">{t('latestActiveMessage')}: </Typography>
                        <Stack>
                          <Typography variant="body2" color="common.black" data-testid="lastNotificationName">
                            {contactInfo.lastNotificationInfo.name}
                          </Typography>
                          <Stack direction="row" alignItems="baseline">
                            <Typography
                              variant="caption1"
                              color="neutral.var60"
                              data-testid="lastNotificationAgentAndDate"
                            >
                              {t('sentBy')} {contactInfo.lastNotificationInfo.agent},
                              {contactInfo.lastNotificationInfo.date}
                            </Typography>
                          </Stack>
                        </Stack>
                      </ContactInfoView>
                      <Typography
                        data-testid="btnShowAllNotifications"
                        variant="body1"
                        onClick={() => handleShowAllContactNotifications(contactInfo._id)}
                        minWidth="55px"
                        color="primary.main"
                        sx={{ cursor: 'pointer' }}
                      >
                        {t('showAllChatsHistory')}
                      </Typography>
                    </Stack>
                  ) : null}
                </Contact>
              </div>
            )}
          </Grid>
          <div
            style={{
              bottom: 0,
              width: '-webkit-fill-available',
            }}
          >
            <Divider width="" fullWidth />
            <Button
              variant="outlined"
              data-testid="buttonEditInfo"
              id="buttonEditInfo"
              sx={{
                position: 'sticky',
                margin: '8px',
                width: '-webkit-fill-available',
              }}
              onClick={() => {
                setEditing(true);
              }}
              startIcon={<i className="fa-solid fa-pencil" />}
            >
              {t('editInformation')}
            </Button>
          </div>
        </div>
      ) : null}

      <GenericModal
        open={showModaAllContactNotifications}
        title={`${t('activeMessagesSentTo')} ${contactInfo?.name}`}
        hasCloseIcon
        handleModal={() => setShowModaAllContactNotifications(false)}
      >
        <TableContainer data-testid="allNotifications" sx={{ maxHeight: '80vh' }}>
          <CustomTable handleClickItem={() => {}} page={1} headItems={headerColumns} rows={tableRows} />
        </TableContainer>
      </GenericModal>
    </>
  );
};

export default ContactInformation;

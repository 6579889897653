/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
import React, { useContext, useEffect, useRef, useState } from 'react';
import ChatsHistory from 'components/ContextDrawer/ChatsHistory';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { Button, IconButton, Tooltip } from '@engyalo/design-system';
import { checkDisableDiscussion, checkDisableTransfer } from 'helpers/checkDisableButton';
import AuthContext from 'contexts/auth/context';
import TransferProvider from 'contexts/transfer/provider';
import MenuTransfer from 'components/Transfer';
import { MENU } from 'views/home/Chat/Header/Header';
import { ChatModalAction } from 'contexts/chats/types.d';
import ConversationContext from 'contexts/conversation/context';
import SelectAgentProvider from 'contexts/selectAgentContext/provider';
import Discussion from 'views/home/Discussion';
import CannedMessagesProvider from 'contexts/cannedMessages/provider';
import MenuChatsContext from 'contexts/menuChats/context';
import usePermissions from 'hooks/usePermissions';
import { KebabOptions } from '../KebabOptions';
import useSegment from '../../services/Analytics';
import { getRoomInfo } from '../../services/getRoomInfo';
import SnackbarCustom from '../Snackbar';
import InputSearch from '../InputSearch';
import ChatsContext from '../../contexts/chats/context';
import { IRoom } from '../../types/room';
import { DrawerMessageType, IDrawerMessages } from './types.d';
import { Container, Header, TitleContainer } from './style';

const USERS_COUNT_DISABLE_END_CHAT = 1;

const DrawerMessages: React.FC<IDrawerMessages> = (props: IDrawerMessages) => {
  const { t } = useTranslation();
  const { roomId, type, onClose } = props;
  const [room, setRoom] = useState<IRoom>({} as IRoom);
  const [loading, setLoading] = useState(true);
  const { currentUserInfo } = useContext(AuthContext);
  const { serviceSelected, toggleCloseChatModal, discussionId, handleOpenDiscussion, userIsInDiscussion, isMobile } =
    useContext(ChatsContext);
  const { setOpenChatsManager } = useContext(MenuChatsContext);
  const { handleStartNewConversation } = useContext(ConversationContext);
  const [error, setError] = useState(false);
  const [anchorElKebab, setAnchorElKebab] = useState<HTMLDivElement | null>(null);
  const [openKebab, setOpenKebab] = useState(false);
  const [inputText, setInputText] = useState('');
  const { sendEvent, EVENT_NAMES } = useSegment();
  const [selectedMenu, setSelectedMenu] = useState<MENU | null>(null);
  const discussionButtonRef = useRef<HTMLButtonElement>(null);
  const transferButtonRef = useRef<HTMLButtonElement>(null);
  const { checkPermission } = usePermissions();
  const chatId = roomId || serviceSelected._id;

  const handleKebabOptionsChats = () => {
    setOpenKebab((state) => !state);
  };

  const handleOpenKebab = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorElKebab(event.currentTarget);
  };

  const loadRoom = async () => {
    if (roomId || serviceSelected?._id) {
      const { data } = await getRoomInfo(chatId);
      setRoom(data.room || {});
    }
  };

  const loadValues = async () => {
    await loadRoom();
    setLoading(false);
  };

  const handleSnackbar = () => {
    setError((old: boolean) => !old);
  };

  const handleDrawerClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleFinishChatModalOpen = () => {
    toggleCloseChatModal(ChatModalAction.Open);
  };

  const handleMenuClick = (menu: MENU) => {
    setSelectedMenu(menu);

    if (menu === MENU.MORE_ACTIONS) {
      sendEvent(EVENT_NAMES.USER_CLICKED_ON_KEBABI_MENU);
    }

    if (menu === MENU.DISCUSSION) {
      handleOpenDiscussion(true);
    }
  };

  const handleClose = () => {
    setSelectedMenu(null);
    handleOpenDiscussion(false);
  };

  useEffect(() => {
    loadValues();
  }, [roomId, serviceSelected?._id]);

  return (
    <>
      <Container>
        {!loading && (
          <>
            <Header>
              {type === DrawerMessageType.HISTORY ? (
                <>
                  <TitleContainer>
                    <IconButton
                      onClick={handleDrawerClose}
                      role="headerIconCloseHistory"
                      variant="text"
                      color="neutral2"
                    >
                      <i className="fa-regular fa-arrow-left fa-xs" />
                    </IconButton>
                    <Typography variant="subtitle2" role="headerTitleHistory">
                      {room?.livechatData?.ticket ? `Ticket ${room?.livechatData?.ticket}` : t('serviceHeldInBot')}
                    </Typography>

                    {room?.livechatData?.ticket && (
                      <Tooltip placement="bottom" title={`${t('copyTicket')}`}>
                        <i
                          role="presentation"
                          data-testid="copyTicket"
                          className="fa-light fa-copy"
                          style={{
                            cursor: 'pointer',
                            marginLeft: '8px',
                          }}
                          onClick={() => {
                            navigator.clipboard.writeText(room?.livechatData?.ticket);
                            sendEvent(EVENT_NAMES.USER_COPY_A_TICKET_ID);
                          }}
                        />
                      </Tooltip>
                    )}
                  </TitleContainer>
                  <Stack direction="row" alignItems="center" gap="8px">
                    {!isMobile && (
                      <>
                        <Tooltip title={`${t('internalDiscussion')}`} placement="top">
                          <IconButton
                            ref={discussionButtonRef}
                            data-testid="btnOpenDiscussion"
                            disabled={
                              checkDisableDiscussion(userIsInDiscussion, serviceSelected, currentUserInfo) ||
                              !!serviceSelected?.closedBy
                            }
                            onClick={() => handleMenuClick(MENU.DISCUSSION)}
                          >
                            <i
                              className="fa-regular fa-screen-users"
                              style={{
                                color: 'neutral.var70',
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={`${t('transfer')}`} placement="top">
                          <IconButton
                            ref={transferButtonRef}
                            data-testid="btnOpenTransfer"
                            disabled={
                              checkDisableTransfer(serviceSelected, currentUserInfo) || !!serviceSelected?.closedBy
                            }
                            color="neutral2"
                            variant="outlined"
                            onClick={() => handleMenuClick(MENU.TRANSFER)}
                          >
                            <i className="fa-solid fa-share-all fa-sm" />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}

                    {isMobile ? (
                      <IconButton
                        data-testid="closeChatButtonChatHeader"
                        disabled={
                          Boolean(serviceSelected?.closedBy) ||
                          serviceSelected.usersCount === USERS_COUNT_DISABLE_END_CHAT ||
                          serviceSelected?.servedBy?._id !== currentUserInfo._id
                        }
                        id="closeChat"
                        onClick={handleFinishChatModalOpen}
                        variant="outlined"
                      >
                        <i className="fa-regular fa-box-check fa-sm" />
                      </IconButton>
                    ) : (
                      <Button
                        variant="outlined"
                        disabled={
                          !checkPermission('livechat-monitor') &&
                          serviceSelected?.servedBy?._id !== currentUserInfo._id &&
                          !serviceSelected?.closedBy
                        }
                        onClick={() => {
                          if (serviceSelected?.closedBy) {
                            setOpenChatsManager(false);
                            handleStartNewConversation(true);
                          } else {
                            handleFinishChatModalOpen();
                          }
                        }}
                      >
                        {serviceSelected?.closedBy ? t('newConversation') : t('finalize')}
                      </Button>
                    )}
                    <IconButton
                      role="iconOpenKekabMenu"
                      onClick={(event: any) => {
                        handleOpenKebab(event);
                        handleKebabOptionsChats();
                      }}
                      size="small"
                      variant="text"
                      color="neutral2"
                    >
                      <i className="fa-regular fa-ellipsis-vertical" />
                    </IconButton>
                  </Stack>
                </>
              ) : (
                <TitleContainer>
                  <Typography variant="subtitle2" role="searchMessageTitle">
                    {t('searchMessages')}
                  </Typography>
                </TitleContainer>
              )}

              {type === DrawerMessageType.SEARCH && (
                <IconButton
                  role="headerIconClose"
                  variant="text"
                  color="neutral2"
                  size="small"
                  onClick={handleDrawerClose}
                >
                  <i className="fa-regular fa-xmark" />
                </IconButton>
              )}
            </Header>
            <InputSearch
              value={inputText}
              placeholder="search"
              onChange={(value: string) => {
                setInputText(value);
              }}
            />
            {chatId ? <ChatsHistory key={chatId} chatId={chatId} filterValue={inputText} chatDate={room.ts} /> : null}
            {error && (
              <SnackbarCustom
                open={error}
                message={t('errorSearch') || ''}
                handleClose={() => handleSnackbar()}
                type="error"
              />
            )}
          </>
        )}
      </Container>

      <CannedMessagesProvider>
        <SelectAgentProvider>
          <Discussion
            open={selectedMenu === MENU.DISCUSSION}
            roomId={discussionId}
            anchorEl={discussionButtonRef?.current}
            handleClose={handleClose}
            departmentId={serviceSelected.departmentId}
          />
        </SelectAgentProvider>
      </CannedMessagesProvider>

      <TransferProvider>
        <MenuTransfer
          open={selectedMenu === MENU.TRANSFER}
          departmentId={serviceSelected.departmentId}
          anchorEl={transferButtonRef?.current}
          handleClose={() => setSelectedMenu(null)}
          isMobile={isMobile}
        />
      </TransferProvider>

      {anchorElKebab && (
        <KebabOptions
          anchorElKebab={anchorElKebab}
          open={openKebab}
          handlePopoverKebab={handleKebabOptionsChats}
          itemSelected={room}
          kebabName="chatsManager"
          loadRoom={loadRoom}
        />
      )}
    </>
  );
};

export default DrawerMessages;
